<template>
  <div>
    <div class="text-center my-5">
      <button class="mybtn-blue m-3" @click="$router.push('/billing')">
        go back to the billing page
      </button>
      <button
        class="mybtn m-3"
        @click="downloadPDF()"
        v-loading="isDownloading"
      >
        Download PDF invoice
      </button>
    </div>

    <div id="invoiceContent" v-show="!isMobileOrTablet">
      <div class="header">
        <img
          width="270px"
          height="50px"
          src="../../assets/invoice-pdf/Logo.png"
        />
        <div style="margin-right: 0px">
          <p
            style="font-size: 22px; font-weight: 900; letter-spacing: 2px"
            class="text-blue"
          >
            {{ userInfo.Name }}
          </p>
          <div class="m-1">
            <p>Client ID: {{ userInfo.ClientID }}</p>
            <p>Phone Number: {{ userInfo.PhoneNumber }}</p>
            <p>Email Address: {{ userInfo.EmailAddress }}</p>
            <p>Billing Address: {{ userInfo.BillingAddress1 }}</p>
            <p>{{ userInfo.BillingAddress2 }}</p>
          </div>
        </div>
      </div>

      <!-- profile  -->
      <div>
        <p style="font-weight: 900; font-size: 24px" class="text-main">
          YOUR DETAILED INVOICE
        </p>
        <p
          class="text-main mycard d-inline-block my-2 px-4 py-1"
          style="background-color: #e5f4f9; font-weight: 700; font-size: 24px"
        >
          {{ dataCapacity }}
          <span class="w-300 mx-3">|</span>
          {{ userInfo.PlanDataPrice }}
          <span style="font-size: 14px">/ month</span>
        </p>
        <br />
        <p>Activation Date: {{ userInfo.ActivationDate }}</p>
        <p>Invoice Date: {{ year + "-" + month + "-15" }}</p>
      </div>

      <!-- Detail Break Down -->
      <div class="my-5">
        <b-row class="my-3">
          <b-col cols="4">
            <p>DESCRIPTION</p>
          </b-col>
          <b-col cols="4">
            <p>DETAIL</p>
          </b-col>
          <b-col cols="4">
            <p>PRICE</p>
          </b-col>
        </b-row>
        <hr class="text-grey" style="width: 80%; margin: 10px 0px" />
        <div v-for="(item, index) in invoiceInfo.InvoiceDetail" :key="index">
          <b-row class="my-3">
            <b-col cols="4">
              <p style="font-weight: 300">
                {{ processDescriptionName(item.ItemName) }}
              </p>
            </b-col>
            <b-col cols="4">
              <p style="font-weight: 300">
                {{ processDetailWording(item.ItemDetail) }}
              </p>
            </b-col>
            <b-col cols="4">
              <p
                style="font-weight: 300"
                :class="item.ItemAmount >= 0 ? 'ml-2' : 'ml-0'"
              >
                {{ procssMoneyFormat(item.ItemAmount) }}
              </p>
            </b-col>
          </b-row>
        </div>
        <hr class="text-grey" style="width: 80%; margin: 10px 0px" />
        <b-row class="my-3">
          <b-col cols="4">
            <p class="text-blue">TOTAL</p>
          </b-col>
          <b-col cols="4"> </b-col>
          <b-col cols="4">
            <p
              class="text-blue"
              :class="invoiceInfo.TotalAmount >= 0 ? 'ml-2' : 'ml-0'"
            >
              {{ procssMoneyFormat(invoiceInfo.TotalAmount) }}
            </p>
          </b-col>
        </b-row>

        <div
          class="text-main"
          style="
            background-color: #e5f4f9;
            font-weight: 700;
            padding: 20px 100px;
            margin: 60px -100px;
          "
        >
          <b-row class="justify-content-center align-items-center">
            <b-col cols="8">
              <div>
                <b-row>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 14px">DUE DATE</p>
                  </b-col>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 14px">
                      PAYMENT METHOD
                    </p>
                  </b-col>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 14px">TOTAL DUE</p>
                  </b-col>
                </b-row>
                <hr
                  class="text-main"
                  style="
                    width: 85%;
                    border: 1px #4cccd4 solid;
                    margin: 10px 0px;
                  "
                />
                <b-row>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 24px">
                      {{ year + "-" + month + "-25" }}
                    </p>
                  </b-col>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 24px">
                      {{ userInfo.PaymentMethod }}
                    </p>
                  </b-col>
                  <b-col cols="4">
                    <p style="font-weight: 700; font-size: 24px">
                      {{ procssMoneyFormat(invoiceInfo.TotalAmount) }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="position-relative" style="margin-left: 10px">
                <p style="font-weight: 900; font-size: 20px; margin-top: 10px">
                  SELF-SERVE
                </p>
                <div class="m-2">
                  <p class="m-0">· Manage Your Plan</p>
                  <p class="m-0">· Make Payments</p>
                  <p class="m-0">· Edit Your Profile</p>
                  <button style="margin-top: 20px" class="specialBtn">
                    <a
                      href="https://myaccount.gophonebox.com/login"
                      class="text-white"
                      >go online now</a
                    >
                  </button>
                  <img
                    class="position-absolute"
                    style="top: -8px; right: -100px"
                    width="300px"
                    height="200px"
                    src="../../assets/invoice-pdf/iphone.png"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <p style="font-weight: 300; margin-top: -50px" class="text-main">
          Please see the last page for more information about your invoice
        </p>
      </div>

      <!-- usage summary  -->
      <p
        class="text-blue"
        style="font-size: 24px; font-weight: 900; letter-spacing: 2px"
      >
        USAGE SUMMARY
      </p>

      <!-- AirTimeUsage  -->
      <div>
        <p
          class="text-main"
          style="
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
          "
        >
          Air Time Usage
        </p>
        <hr style="margin: 0px" />
        <div class="usageInfoBar">
          <p>ServiceName</p>
          <p>TotalAirTime</p>
          <p>FreeAirTime</p>
          <p>ChargedAirTime</p>
          <p>ChargeableAirTime</p>
          <p>Total Charge</p>
        </div>
        <div
          v-for="(item, index) in usageInfo.AirTimeUsage"
          :key="index"
          class="usageInfoBar usageInfoBarEachRow"
        >
          <p>{{ item.ServiceName }}</p>
          <p>{{ item.TotalAirTime }}</p>
          <p>{{ item.FreeAirTime }}</p>
          <p>{{ item.ChargedAirTime }}</p>
          <p>{{ item.ChargebleAirTime }}</p>
          <p>{{ item.Total }}</p>
        </div>
      </div>

      <!-- Data Add On Usage  -->
      <div>
        <p
          class="text-main"
          style="
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
          "
        >
          Data and Add On
        </p>
        <hr style="margin: 0px" />
        <div class="usageInfoBar">
          <p>ServiceName</p>
          <p>TotalDataUsedKB</p>
          <p>IncludedDataKB</p>
          <p>ChargeableDataKB</p>
          <p>Total Charge</p>
        </div>
        <div
          v-for="(item, index) in usageInfo.DataAddOnUsage"
          :key="index"
          class="usageInfoBar usageInfoBarEachRow"
        >
          <p>{{ item.ServiceName }}</p>
          <p>{{ item.TotalDataUsedKB }}</p>
          <p>{{ item.IncludedDataKB }}</p>
          <p>{{ item.ChargebleDataKB }}</p>
          <p>{{ item.Total }}</p>
        </div>
      </div>

      <!-- Text Message Usage  -->
      <div>
        <p
          class="text-main"
          style="
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
          "
        >
          Text Message Usage
        </p>
        <hr style="margin: 0px" />
        <div class="usageInfoBar">
          <p>ServiceName</p>
          <p>TotalMessage</p>
          <p>IncludedMessage</p>
          <p>ChargeableMessage</p>
          <p>Total Charge</p>
        </div>
        <div
          v-for="(item, index) in usageInfo.TextMessageUsage"
          :key="index"
          class="usageInfoBar usageInfoBarEachRow"
          v-show="item.ServiceName !== null"
        >
          <p>{{ item.ServiceName }}</p>
          <p>{{ item.TotalMessage }}</p>
          <p>{{ item.IncludedMessage }}</p>
          <p>{{ item.ChargebleMessage }}</p>
          <p>{{ item.Total }}</p>
        </div>
      </div>

      <!-- Othter Services Usage  -->
      <div v-show="usageInfo.hasOtherServicesUsage">
        <p
          class="text-main"
          style="
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
          "
        >
          Other Services Usage
        </p>
        <hr style="margin: 0px" />
        <div class="usageInfoBar">
          <p>ServiceName</p>
          <p>TotalMIN</p>
          <p>FreeMIN</p>
          <p>IncludedMIN</p>
          <p>ChargeableMIN</p>
          <p>Total Charge</p>
        </div>
        <div
          v-for="(item, index) in usageInfo.OthterServicesUsage"
          :key="index"
          class="usageInfoBar usageInfoBarEachRow"
        >
          <p>{{ item.ServiceName }}</p>
          <p>{{ item.TotalMIN }}</p>
          <p>{{ item.FreeMIN }}</p>
          <p>{{ item.IncludedMIN }}</p>
          <p>{{ item.ChargebleMIN }}</p>
          <p>{{ item.NA }}</p>
        </div>
      </div>

      <!-- Roaming Service Usage  -->
      <div v-show="usageInfo.hasRoamingServiceUsage">
        <p
          class="text-main"
          style="
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
          "
        >
          Roaming Service Usage
        </p>
        <hr style="margin: 0px" />
        <div class="usageInfoBar">
          <p>ServiceName</p>
          <p>TotalRM</p>
          <p>FreeRM</p>
          <p>IncludedRM</p>
          <p>ChargeableRM</p>
          <p>Total Charge</p>
        </div>
        <div
          v-for="(item, index) in usageInfo.RoamingServiceUsage"
          :key="index"
          class="usageInfoBar usageInfoBarEachRow"
        >
          <p>{{ item.ServiceName }}</p>
          <p>{{ item.TotalRM }}</p>
          <p>{{ item.FreeRM }}</p>
          <p>{{ item.IncludedRM }}</p>
          <p>{{ item.ChargebleRM }}</p>
          <p>{{ item.NA }}</p>
        </div>
      </div>

      <!-- Rogers Daily Data Usage  -->
      <div v-show="usageInfo.hasRogersDailyDataUsage">
        <p
          class="text-blue mt-5 mb-3"
          style="font-size: 24px; font-weight: 900; letter-spacing: 2px"
        >
          ROGERS DAILY DATA USAGE
        </p>
        <b-row>
          <b-col>
            <div
              v-for="(item, index) in rogersDailyDataUsageFirstHalf"
              :key="index"
              class="float-left"
            >
              <p class="m-2">
                <span class="m-2">
                  <span class="mr-3 text-main">Date:</span>
                  {{ item.Date }}</span
                >
                <span class="m-2">
                  <span class="mr-3 text-main">Used Data:</span>
                  {{ item.UsedDataKB.split(".")[0] }} KB
                </span>
              </p>
            </div>
          </b-col>
          <b-col>
            <div
              v-for="(item, index) in rogersDailyDataUsageSecondHalf"
              :key="index"
              class="float-left ml-5 pl-4"
            >
              <p class="m-2">
                <span class="m-2">
                  <span class="mr-3 text-main">Date:</span>
                  {{ item.Date }}</span
                >
                <span class="m-2">
                  <span class="mr-3 text-main">Used Data:</span>
                  {{ item.UsedDataKB.split(".")[0] }} KB
                </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- call history  -->
      <div>
        <p
          class="text-blue mt-5"
          style="font-size: 24px; font-weight: 900; letter-spacing: 2px"
        >
          CALL HISTORY
        </p>
        <div class="callingInfoBar text-main">
          <p>Call</p>
          <p>Date</p>
          <p>Time</p>
          <p>From</p>
          <p>CallLength</p>
          <p>To</p>
          <p>NumberCalled</p>
          <p>CallType</p>
          <p>LDRoaming</p>
        </div>
        <div v-for="(item, index) in callInfo" :key="index">
          <div class="callingInfoBar callingInfoBarEachRow">
            <p>{{ item.Call }}</p>
            <p>{{ item.Date }}</p>
            <p>{{ item.Time }}</p>
            <p>{{ item.From }}</p>
            <p>{{ item.CallLength }}</p>
            <p>{{ item.To }}</p>
            <p>{{ item.NumberCalled }}</p>
            <p>{{ item.CallType }}</p>
            <p>{{ item.LDRoaming }}</p>
          </div>
        </div>
      </div>

      <!-- static information  -->
      <div class="my-5 staticInfo">
        <p class="mb-2"><strong>INFORMATION ABOUT YOUR INVOICE</strong></p>
        <p>
          All of our invoice notifications are sent via email and require you to
          log in online at
          <a class="text-blue" href="https://myaccount.gophonebox.com/login"
            >https://myaccount.gophonebox.com/login</a
          >
          to view and download in pdf.
        </p>
        <p>
          Billing Cycle starts from the 2nd of every month to the 1st of the
          following month. Your minutes, messages and data will reset to your
          allotted data on 2nd of every month.
        </p>
        <p>
          The payment period is from the 15th to 25th of every month. Invoice
          payments are due on the 25th of every month. Late payments may be
          charged an additional 5% of the amount owed for next month.
        </p>
        <p>
          Your regular monthly bill will include next month’s fee + last months
          over usage. Please note that any overages for previous month(s) may be
          added to your next invoice.
        </p>
        <br />
        <p class="mb-2"><strong>ADDITIONAL SERVICE FEES</strong></p>
        <p>
          - Data overages is Amount charged for using data in excess of your
          monthly data allowance.
        </p>
        <p>- Late payment fee is additional 5% of the amount owed.</p>
        <p>
          - Plan change adjustment is fee charged or credited based on new plan
          selection.
        </p>
        <p>
          - 411 Fees is Amount charged when you contact the 411 Canada Wide
          Directory Assistance to request for an address or a phone number or
          contact an operator not using a mobile number
        </p>
        <br />
        <p class="mb-2"><strong>PAYMENT OPTIONS</strong></p>
        <p>Invoice Payments are due on the 25th of every month.</p>
        <p>- Automated payments by your credit card.</p>
        <p>
          - Setup automatic withdrawals from your bank account. Please contact
          Customer Care Team to receive PAD form and send it out to us via
          email.
        </p>
        <p>- Pay via Online banking through your bank.</p>
        <p>
          - On our website by logging in Online at
          <a class="text-blue" href="https://gophonebox.com/"
            >www.gophonebox.com</a
          >
          with your credit card, Alipay, WeChat Pay or PayPal.
        </p>
        <p>- Pay in person at one of our PhoneBox locations.</p>
        <br />
        <p>
          * Pre-authorized debit is withdrawn from your account before the 16th
          of the month.
        </p>
        <p>
          * Automatic credit card payments will be withdrawn on 19th and 25th of
          the month
        </p>
        <p>
          * Direct deposits that are made without sufficient funds or with
          inaccurate information will incur a $25 + tax Charge.
        </p>
        <br />
        <p>
          See our Terms & Conditions
          <a
            class="text-blue"
            href="https://lega.gophonebox.com/en/Help/ServicesAndPolicies"
            >here.</a
          >
        </p>
        <br />
        <p style="font-weight: 500;">PhoneBox Vancouver</p>
        <p>658 Seymour Street, Vancouver, BC V6B 3K4</p>
        <br />
        <p style="font-weight: 500;">PhoneBox Toronto</p>
        <p>1240 Bay St. #305, Toronto, ON M5R 2A7</p>
        <br />
        <p>GST/HST: 820146918 RT0001</p>
        <p>QST: 1222465636 TQ0001</p>
      </div>
    </div>
  </div>
</template>

<script>
// import htmlToPdfWithoutPage from "../../utils/htmlToPdf";
import htmlToPdf from "../../utils/htmlToPdf";
export default {
  data() {
    return {
      isMobileOrTablet: false,
      isDownloading: false,
      dataCapacity: "",
      userInfo: {},
      invoiceInfo: {},
      usageInfo: {},
      callInfo: {},
      rogersDailyDataUsage: {},
      //slice this data to two parts for two columns side-by-side display
      rogersDailyDataUsageFirstHalf: {},
      rogersDailyDataUsageSecondHalf: {},
    };
  },
  computed: {
    year() {
      return this.$store.state.pdfInvoiceData.postpaid.year;
    },
    month() {
      return this.$store.state.pdfInvoiceData.postpaid.month;
    },
  },

  methods: {
    downloadPDF() {
      this.isDownloading = true;
      let pdfFileName =
        this.userInfo.FirstName +
        "-" +
        this.userInfo.LastName +
        "-" +
        this.year +
        "-" +
        this.month +
        "-Invoice";
      if (this.isMobileOrTablet) {
        this.isMobileOrTablet = false;
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isMobileOrTablet = true;
          this.isDownloading = false;
        }, 1500);
      } else {
        setTimeout(() => {
          htmlToPdf.exportPDFWithoutPage(pdfFileName);
          this.isDownloading = false;
        }, 1500);
      }
    },
    updateActDateFormat() {
      let actDate = this.userInfo.ActivationDate.split(" ")[0];
      let actDateBreak = actDate.split("/");
      this.userInfo.ActivationDate =
        actDateBreak[2] + "-" + actDateBreak[0] + "-" + actDateBreak[1];
    },
    getMonthlyFeeDate(month, year) {
      let m = parseInt(month);
      let y = parseInt(year);
      var from, to;
      // check if month > 12
      if (m + 1 > 12) {
        from = y + 1 + "-" + (m + 1 - 12) + "-2";
      } else {
        from = y + "-" + (m + 1) + "-2";
      }
      if (m + 2 > 12) {
        to = y + 1 + "-" + (m + 2 - 12) + "-1";
      } else {
        to = y + "-" + (m + 2) + "-1";
      }
      return from + " ~ " + to;
    },
    getDataCapacity() {
      this.$axios
        .get("/Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data).DataCapacity;
          this.dataCapacity = data > 10000 ? "Unlimited" : data + " GB";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataFeeDate(month, year) {
      let m = parseInt(month);
      let y = parseInt(year);
      var from, to;
      // check if month < 1
      if (m - 1 < 1) {
        from = y - 1 + "-" + (m - 1 + 12) + "-2";
      } else {
        from = y + "-" + (m - 1) + "-2";
      }
      to = y + "-" + m + "-1";
      return from + " ~ " + to;
    },
    processDescriptionName(value) {
      switch (value) {
        case "EMPOYEE BENEFIT":
          return "FAMILY DISCOUNT";
        default:
          return value;
      }
    },
    processDetailWording(value) {
      // console.log(value);
      switch (value) {
        case "MONTHLY SERVICE FEES":
          return this.getMonthlyFeeDate(this.month, this.year);
        // data from api sometime has one more space
        case "EMPLOYEE  Discount":
          return "";
        case "EMPLOYEE Discount":
          return "";
        case "PREVIOUS BALANCE":
          return "";
        case "DATA":
          return this.getDataFeeDate(this.month, this.year);
        case "Interest Charge 5%":
          return "Late Payment Fee";
        case "MONTHLY GAME FEE":
          return "Application Purchase";
        default:
          return value;
      }
    },
    processNumberFormat(value) {
      return (
        "1+ (" +
        value.slice(0, 3) +
        ")" +
        " " +
        value.slice(3, 6) +
        "-" +
        value.slice(6, 10)
      );
    },
    procssMoneyFormat(value) {
      let newNumber = (Math.round(value * 100) / 100).toFixed(2);
      if (newNumber < 0) {
        return "- $ " + newNumber.split("-")[1];
      } else {
        return "$ " + newNumber;
      }
    },
    getUserInfoByYearMonth() {
      this.$axios
        .get("/Account/UserInfoByYearMonth", {
          params: {
            year: this.year,
            month: this.month,
          },
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          this.userInfo = data;
          this.userInfo.Name =
            this.userInfo.FirstName + " " + this.userInfo.LastName;
          this.userInfo.BillingAddress1 =
            this.userInfo.BillingAddress.split(",")[0] + ",";
          this.userInfo.BillingAddress2 =
            this.userInfo.BillingAddress.split(",")[1] +
            "," +
            this.userInfo.BillingAddress.split(",")[2] +
            "," +
            this.userInfo.BillingAddress.split(",")[3] +
            "," +
            this.userInfo.BillingAddress.split(",")[4];
          this.userInfo.PhoneNumber = this.processNumberFormat(
            this.userInfo.PhoneNumber
          );
          this.userInfo.PlanDataPrice = this.userInfo.PlanDataPrice.split(
            "/"
          )[0];
          this.updateActDateFormat();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInvoiceInfoByYearMonth() {
      this.$axios
        .get("/Account/InvoiceInfoByYearMonth", {
          params: {
            year: this.year,
            month: this.month,
          },
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          this.invoiceInfo = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsageInfoByYearMonth() {
      this.$axios
        .get("/Account/UsageInfo", {
          params: {
            year: this.year,
            month: this.month,
          },
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          this.usageInfo = data;
          this.usageInfo.hasOtherServicesUsage =
            this.usageInfo.OthterServicesUsage.length !== 0;
          this.usageInfo.hasRoamingServiceUsage =
            this.usageInfo.RoamingServiceUsage.length !== 0;
          this.usageInfo.hasRogersDailyDataUsage =
            this.rogersDailyDataUsage.length !== 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCallHistoryByYearMonth() {
      this.$axios
        .get("/Account/CallHistory", {
          params: {
            year: this.year,
            month: this.month,
          },
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          this.callInfo = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRogersDataUsageBreakDownByYearMonth() {
      this.$axios
        .get("/Account/RogersDataUsageBreakdown", {
          params: {
            year: this.year,
            month: this.month,
          },
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          this.rogersDailyDataUsage = data;
          var length = this.rogersDailyDataUsage.length;
          var mid = Math.floor(length / 2);
          this.rogersDailyDataUsageFirstHalf = this.rogersDailyDataUsage.slice(
            0,
            mid
          );
          this.rogersDailyDataUsageSecondHalf = this.rogersDailyDataUsage.slice(
            mid,
            length
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
    this.getUserInfoByYearMonth();
    this.getInvoiceInfoByYearMonth();
    this.getDataCapacity();
    this.getUsageInfoByYearMonth();
    this.getCallHistoryByYearMonth();
    this.getRogersDataUsageBreakDownByYearMonth();
  },
};
</script>

<style lang="scss" scoped>
h2 {
  letter-spacing: 1px;
}

h3 {
  font-weight: 500;
  letter-spacing: 1px;
}

p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

#invoiceContent {
  width: 1100px;
  margin: auto;
  padding: 100px;
  margin-bottom: 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.text-white {
  color: #fff;
}

.text-main {
  color: #46cad3;
}

.text-blue {
  color: #2e9ed1;
}

.text-grey {
  color: #b1aeae;
}

.bg-main {
  background-color: #ebf8fa;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-image: linear-gradient(to right, #197eb1, #44c3d1);
}

.mycard {
  border-radius: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breakdownBar {
  width: 80%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.callingInfoBar,
.usageInfoBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.callingInfoBar p,
.usageInfoBar p {
  width: 20%;
  font-weight: 400;
  text-align: center;
  margin: 10px 0px;
}

.callingInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 12px;
  font-weight: 300;
}

.usageInfoBarEachRow p {
  margin: 10px 0px;
  font-size: 13px;
  font-weight: 300;
}

.specialBtn {
  border-radius: 50px;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: 0px;
  padding: 5px 20px;
  text-transform: uppercase;
}

.staticInfo p {
  font-weight: 300;
}

.mycol {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
</style>
